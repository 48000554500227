export default [
  {
    src: 'img/meet2code.jpg',
    srcMob: 'img/mob/meet2code.jpg',
    heading: 'Saas',
    title: 'Saas on React',
    link: 'https://meet2code.com/',
    type: 'react',
  },
  {
    src: 'img/work0.jpg',
    srcMob: 'img/mob/work0.jpg',
    heading: 'Personal account',
    title: 'Multi-page, multi-component site on Vue.js',
    link: 'https://academy.pure-code.ru/dashboard',
    type: 'vue',
  },
  {
    src: 'img/work1.jpg',
    srcMob: 'img/mob/work1.jpg',
    heading: 'Web application',
    title: 'Showing charts in pure JavaScript',
    link: 'https://projects.pure-code.ru/chart-app/',
    type: 'js',
  },
  {
    src: 'img/logictime.jpg',
    srcMob: 'img/mob/logictime.jpg',
    link: 'https://logictime.ru',
  },
  {
    src: 'img/map.jpg',
    srcMob: 'img/mob/map.jpg',
    link: 'https://ymap.pure-code.ru/',
  },
  {
    src: 'img/resh.jpg',
    srcMob: 'img/mob/resh.jpg',
    link: 'https://resh.edu.ru/subject/lesson/3515/main/146238/',
  },
  {
    src: 'img/poiskhome.jpg',
    srcMob: 'img/mob/poiskhome.jpg',
    heading: 'Card heading',
    title: 'Description text',
    link: 'https://projects.pure-code.ru/delivery/',
    type: 'html',
  },
  {
    src: 'img/work2.jpg',
    srcMob: 'img/mob/work2.jpg',
    heading: 'Card heading',
    title: 'Description text',
    link: 'https://projects.pure-code.ru/inventally/',
    type: 'html',
  },
  {
    src: 'img/work4.jpg',
    srcMob: 'img/mob/work4.jpg',
    heading: 'Card heading',
    title: 'Description text',
    link: 'https://projects.pure-code.ru/invoice-app/',
    type: 'html',
  },
  {
    src: 'img/work6.jpg',
    srcMob: 'img/mob/work6.jpg',
    heading: 'Card heading',
    title: 'Description text',
    link: 'https://projects.pure-code.ru/chat/',
    type: 'html',
  },
  {
    src: 'img/catering.jpg',
    srcMob: 'img/mob/catering.jpg',
    link: 'https://projects.pure-code.ru/catering/',
  },
  {
    src: 'img/rassvet.jpg',
    srcMob: 'img/mob/rassvet.jpg',
    link: 'https://projects.pure-code.ru/rassvet/',
  },
];